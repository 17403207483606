import '@confidenceman02/elm-select'
import { getStorage, setStorage, storageHandlerVersion } from './storage'
import './js/crisp'
import './js/editor'
import './js/datepicker'
import './js/errors_tracker'
import './js/gantt-component'
import './js/google-picker-api'
import './js/measurement'
import * as AbsintheSocket from '@absinthe/socket'
import { Socket as PhoenixSocket } from 'phoenix'
import PortFunnels from './js/PortFunnels'
import LocalStorage from './js/PortFunnels/LocalStorage'
import './popper-container'
import { Elm } from './Main.elm'
import ElmCroppie from './js/croppie'

import './css/main.scss'

// import { send } from 'vite'
// import { ap } from 'rambda'

// if (process.env.NODE_ENV === 'development') {
//   const monitor = require('elm-monitor')
//   monitor()
// }

// import * as ElmDebugger from 'elm-debug-transformer'
// if (process.env.NODE_ENV === 'development') {
//   ElmDebugger.register({ simple_mode: false, debug: false, limit: 1000000 })
// }

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(reg => reg)
    .catch(err => err)
}

// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt: Event | null = null
let isPWAInstalled = true

const displayMode: string = window.matchMedia('(display-mode: standalone)').matches
  ? 'standalone'
  : 'browser'

const wrap = (fun: (arg: string) => any) =>
  new Proxy(
    {},
    {
      get(_target, arg) {
        return fun(arg as string)
      },
      has(_target, arg) {
        return true
      },
    }
  )

const localeCompareProxy = wrap(json => {
  try {
    const [a, b] = JSON.parse(json)

    return a.localeCompare(b)
  } catch (e) {
    return void 0
  }
})

// localStorage.setItem('env', process.env.NODE_ENV || 'production')1

document.body.addEventListener('dragover', ev => {
  // this is needed in order to make dragging work
  ev.preventDefault()

  return false
})

document.body.addEventListener('keydown', ev => {
  // prevent default browser action when key actions happen in elm (f - find - opens search dialog)
  if (ev.key === 'f' && (ev.ctrlKey || ev.metaKey)) {
    ev.preventDefault()
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const jwt = localStorage.getItem('user.jwt')

  const app = (window._elmApp = Elm.Main.init({
    flags: {
      displayMode,
      env: process.env.NODE_ENV || 'production',
      jwt: jwt !== null ? JSON.parse(jwt) : null,
      // @ts-ignore: localeCompareProxy is a D.value which can't be defined in TS
      localeCompare: localeCompareProxy,
      platform: navigator.userAgent.includes('Macintosh') ? 'Mac' : 'Other',
      storage: getStorage(),
      storageHandlerVersion,
      time: Date.now(),
      visibility: document.visibilityState,
      zoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      zoneOffset: -new Date().getTimezoneOffset(),
    },
  }))

  PortFunnels.subscribe(app)
  PortFunnels.registerModule(LocalStorage)

  makeSocketConnection(app.ports)

  app.ports.saveStorage.subscribe((payload: object) => {
    setStorage(payload)
    makeSocketConnection(app.ports)
    app.ports.loadStorage.send(payload)
  })

  // check if i can install pwa ( trick to check if pwa is already installed)
  window.addEventListener('beforeinstallprompt', e => {
    isPWAInstalled = false
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault()
    // Stash the event so it can be triggered later.
    deferredPrompt = e
    // Update UI notify the user they can install the PWA

    setPWAInstallationStatus(app.ports)
  })

  app.ports.installPWA.subscribe(async () => {
    deferredPrompt.prompt()
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice

    isPWAInstalled = outcome === 'accepted'

    deferredPrompt = null
    setPWAInstallationStatus(app.ports)
  })

  setPWAInstallationStatus(app.ports)

  app.ports.croppie.subscribe(data => {
    ElmCroppie.port(data)
  })

  app.ports.events.subscribe(ev => {
    if (typeof ev === 'object' && ev.event == 'LanguageChanged') {
      document.cookie = 'lang=' + ev.value + '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT'
      document.location.reload()
    }

    if (typeof ev === 'object' && ev.event == 'LanguageChecked') {
      const cookieLanguage =
        document.cookie
          .split(';')
          .find(c => c.trim().startsWith('lang='))
          ?.split('=')[1] || 'cs'

      if (cookieLanguage !== ev.value) {
        document.cookie = 'lang=' + ev.value + '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT'
        document.location.reload()
      } else return false
    }

    if (
      typeof ev === 'object' &&
      (ev.event == 'CopyCalendarIntegrationLink' || ev.event == 'CopyToClipboard')
    ) {
      navigator.clipboard.writeText(ev.value)
    }

    if (typeof ev === 'object' && ev.event == 'CopyTaskUrl') {
      const url = new URL(window.location.href)
      const urlWithoutQuery = `${url.origin}${url.pathname}`

      navigator.clipboard.writeText(urlWithoutQuery)
    }

    if (typeof ev === 'object' && ev.event == 'ResetKanbanColumns') {
      const containers = document.querySelectorAll('.kanban-column')
      // reset scroll position of all containers to 0
      containers.forEach(container => {
        container.scrollTop = 0
      })
    }
  })

  window.addEventListener('paste', ev => {
    const appTaskDetail = document.getElementById('app-task-detail')

    if (!appTaskDetail) return

    const clipboardData = (ev as ClipboardEvent).clipboardData

    if (!clipboardData) return

    const items: DataTransferItemList = clipboardData.items

    Array.from(items).forEach((item: DataTransferItem) => {
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile()

        if (blob !== null) {
          const reader = new FileReader()

          reader.onload = function (event) {
            const base64Data = event.target?.result

            const newEvent = new CustomEvent('image-pasted', {
              detail: base64Data,
            })

            appTaskDetail.dispatchEvent(newEvent)
          }

          reader.onerror = function (error) {
            console.error('Error reading file', error)
          }

          reader.readAsDataURL(blob)
        }
      }
    })
  })
})

let absintheSocket: any = null
let notifiers: Record<string, object> = {}

function makeSocketConnection(ports: object) {
  const storage = getStorage()

  if (
    absintheSocket !== null ||
    [null, void 0].includes(storage.user) ||
    ports.createSubscription === void 0
  ) {
    return
  } else {
    const protocol: string = location.host.slice(0, 9) != 'localhost' ? 'wss' : 'ws'

    absintheSocket = AbsintheSocket.create(
      new PhoenixSocket(`${protocol}://${location.host}/graphql/socket`, {
        params: { token: storage.user.jwt },
      })
    )

    ports.createSubscription.subscribe((spec: { doc: string; name: string }) => {
      // remove existing notifiers
      if (notifiers[spec.name] !== void 0) {
        AbsintheSocket.cancel(absintheSocket, notifiers[spec.name])
      }

      // create new notifiers for each subscription sent
      notifiers[spec.name] = AbsintheSocket.send(absintheSocket, {
        operation: spec.doc,
        variables: {},
      })

      function onStart(name: string) {
        return () => {
          // console.log('>>> Start', name, JSON.stringify(data))
          ports.socketStatusConnected.send(name)
        }
      }

      // function onAbort(name: string) {
      //   // return data => console.log('>>> Abort', name, JSON.stringify(data))
      // }

      // function onCancel(name: string) {
      //   // return data => console.log('>>> Cancel', name, JSON.stringify(data))
      // }

      function onError(name: string) {
        return () => {
          // console.log('>>> Error', name, JSON.stringify(data))
          ports.socketStatusReconnection.send(name)
        }
      }

      function onResult(name: string) {
        return (data: object) => {
          // console.log('>>> Result', name, JSON.stringify(data))
          ports.subscriptionMessage.send([name, data])
        }
      }

      AbsintheSocket.observe(absintheSocket, notifiers[spec.name], {
        // onAbort: onAbort(spec.name),
        // onCancel: onCancel(spec.name),
        onError: onError(spec.name),
        onResult: onResult(spec.name),
        onStart: onStart(spec.name),
      })
    })
  }
}

window.addEventListener('appinstalled', () => {
  isPWAInstalled = true
  // Clear the deferredPrompt so it can be garbage collected
  deferredPrompt = null
})

const setPWAInstallationStatus = (ports: object) => {
  ports.pWAInstalled.send(isPWAInstalled)
}
